(function ($) {

  'use strict';

  Drupal.behaviors.ladaptRecherche = {
    attach: function (context, settings) {
      if ($('#recherche', context).length && $('#search-dialog-container', context).length == 0) {
        $('#recherche').remove();
        return;
      }
      $("#recherche", context)
        .once('ladapt-search')
        .find('.toggle')
        .click(function (e) {
          e.preventDefault();

          if($('#recherche').is('.opened')) {
            $('body').removeClass('recherche-opened');
            $('.search').addClass('closed').delay(400);
            $('.dialog').dialog('close');
            return;
          }

          $('#recherche').addClass('opened');
          $('body').addClass('recherche-opened');
          $('#search-dialog-container').dialog('open');
          $('.search').removeClass('closed');

          $('#hamburger').removeClass('open');
          $('html').removeClass('no-scroll');
          $('#menu-principal').addClass('element-invisible');
          $('#lightbox2-overlay').hide();
        })
        .parents('.section')
        .find('.dialog')
        .dialog({
          autoOpen: false,
          draggable: false,
          resizable: false,
          width: 'auto',
          height: 132,
          dialogClass: 'search',
          position: {my: "top", at: "top", of: "header"},
          close: function () {
            $('#recherche').removeClass('opened');
            $('body').removeClass('recherche-opened');
          }
        })
        .tabs();
    }
  };

})(jQuery);
