/**
 * Ce fichier replique les entêtes pour faire des tableaux responsives avec
 * CSS.
 */

(function($) {

  Drupal.behaviors.ladaptTable = {
    attach: function(context, settings) {
      $('.node table', context).each(function() {
        var head = $(this).find('thead th');
        for (var i = 1; i < head.length; i++) {
          var label = head[i].innerText;
          $(this).find('tr').find('td:eq(' + (i-1) + ')').attr('data-label', label);
        }
      });
    }
  };

})(jQuery);
