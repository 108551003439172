(function($) {

  Drupal.behaviors.jsEnabled = {
    attach: function(context, settings) {
      $('body').addClass('js');
    }
  };

  Drupal.behaviors.externalLinks = {
    attach: function(context, settings) {
      $('a[rel*=external]').click(function(e) {
        if (this.href != '#') {
          window.open(this.href);
        }
        return false;
      });
    }
  };

  Drupal.behaviors.ladaptBackgroundGradient = {
    attach: function(context, settings) {

      setTimeout(function() {
        var stops = []; // array to hold all stop positions
        var top = $('div.l-main').offset(); // base
        var y = 0;
        var h = 0;

        // Check if we have an intro separator for this content
        var introsep = $('div.intro-separator').offset();
        if (!!introsep) {
          y = Math.round(introsep.top - top.top) + 7;
          stops.push({'color': '#f2efe2', 'y': '0px'});
          stops.push({'color': '#f2efe2', 'y': y + 'px'});
          stops.push({'color': '#d2ccb9', 'y': (y + 1) + 'px'});
          stops.push({'color': '#f6f3ed', 'y': (y + 2) + 'px'});
        }
        else {
          stops.push({'color': '#f6f3ed', 'y': 0});
        }

        // Check if we have a postface1 region
        var postface1 = $('div.l-postface1').offset();
        if (!!postface1) {
          h = $('div.l-postface1').height();
          y = Math.round(postface1.top - top.top);
          stops.push({'color': '#f6f3ed', 'y': y + 'px'});
          stops.push({'color': '#bfbba7', 'y': (y + 1) + 'px'});
          stops.push({'color': '#e3dfcc', 'y': (y + 4) + 'px'});
          stops.push({'color': '#e3dfcc', 'y': (y + h) + 'px'});
          stops.push({'color': '#f6f3ed', 'y': (y + h + 1) + 'px'});
        }

        // Check if we have a some extra groups (div) with a class "bg-darker"
        $('div.bg-darker').each(function(index) {
          var pos = $(this).offset();
          h = $(this).height();
          y = Math.round(pos.top - top.top);
          stops.push({'color': '#f6f3ed', 'y': y + 'px'});
          stops.push({'color': '#bfbba7', 'y': (y + 1) + 'px'});
          stops.push({'color': '#e3dfcc', 'y': (y + 4) + 'px'});
          stops.push({'color': '#e3dfcc', 'y': (y + h) + 'px'});
          stops.push({'color': '#f6f3ed', 'y': (y + h + 1) + 'px'});
        });

        // Prepare the gradient strings
        var def_standard = array_map(function(a) {
            return a.color + ' ' + a.y;
          }, stops
        );
        var def_oldwebkit = array_map(function(a) {
            return 'color-stop(' + a.y + ',' + a.color + ')';
          }, stops
        );

        // Build gradient for all browsers
        // Old IE use http://css3pie.com/
        var str = '';
        str += ' background-image: -moz-linear-gradient(top, ' + def_standard.join(',') + ');';
        str += ' background-image: -webkit-gradient(linear, left top, left bottom, ' + def_oldwebkit.join(',') + ');';
        str += ' background-image: -webkit-linear-gradient(top, ' + def_standard.join(',') + ');';
        str += ' background-image: -o-linear-gradient(top, ' + def_standard.join(',') + ');';
        str += ' background-image: -ms-linear-gradient(top, ' + def_standard.join(',') + ');';
        str += ' background-image: linear-gradient(top, ' + def_standard.join(',') + ');';
        str += ' -pie-background: linear-gradient(to bottom, ' + def_standard.join(',') + ');';
        $('div.l-main-wrapper').attr('style', str);

      }, 2000); // Delay background gradient init to get proper height.

    }
  };

  Drupal.behaviors.ladaptFoldableMenu = {
    attach: function(context, settings) {
      $('#block-system-main-menu')
        .once('foldable')
        .find('.nolink')
        .wrapInner('<span class="inner"/>')
        .end()
        .find('li > span.nolink')
        .attr('role', 'tab')
        .attr('tabindex', '0')
        .end()
        .find('ul.menu > li > ul.menu')
        .attr('role', 'tabpanel')
        .end()
        .find('ul.menu > li.expanded')
        .attr('aria-expanded', 'false')
        .end()
        .find('li:not(.active-trail)')
        .addClass('collapsed')
        .find('ul')
        .hide()
        .end()
        .end()
        .find('li.expanded')
        .css('cursor', 'pointer')
        .find('.nolink')
        .click(function() {
          var p = $(this).parent();
          if (p.hasClass('collapsed')) {
            $('#block-system-main-menu li:not(.collapsed)').addClass('collapsed').find('ul').slideUp();
            p.removeClass('collapsed').find('ul').slideDown();
            p.attr('aria-expanded', 'true');
          }
          else {
            p.addClass('collapsed').find('ul').slideUp();
            p.attr('aria-expanded', 'false');
          }
        })
        .bind('keypress', function(e) {
          if (e.keyCode == 13 || e.keyCode == 32) {
            $(this).trigger('click');
          }
        });
    }
  };

  Drupal.behaviors.ladaptfieldbodystyle = {
    attach: function(context, settings) {
      $('.node div.field--body ol li').wrapInner('<span class="inner-ol" />');
    }
  };

  Drupal.behaviors.equalHeights = {
    attach: function(context) {
      // Accueil : Equal height on actus title
      var items = $('#block-views-actualites-block .views-row.small h2');
      var maxHeight = 0;
      $(items).each(function() {
        var height = $(this).height();
        if (height > maxHeight) {
          maxHeight = height;
        }
      });
      $(items).height(maxHeight);
    }
  };

  Drupal.behaviors.fixedOverlay = {
    attach: function(context) {
      // Show and Hide the overlay region when scrolling down the page
      jQuery(window).scroll(function() {
        var overlay = $('.l-overlay-wrapper');
        if ($(window).scrollTop() > 234) {
          if (overlay.is(":hidden")) {
            // Show the overlay
            overlay.show();
          }
        }
        else {
          if (overlay.is(":visible")) {
            // Hide the overlay
            overlay.hide();
          }
        }
      });
    }
  };

  Drupal.behaviors.hashTagScroll = {
    attach: function(context) {
      // If user load a page with a hash
      if (window.location.hash) {
        scrollToHash();
      }

      // Detect hash change (if user type a new hash in the address bar)
      $(window).on('hashchange', scrollToHash);
      $('.scroll-to-chapter').click(function() {
        $('.scroll-to-chapter').removeClass('current');
        $(this).addClass('current');
        destination = $(this).attr('href');
        scrollToHash(destination);
        location = destination;
        return false;
      });
    }
  };

  Drupal.behaviors.qtipsEtablissement = {
    attach: function(context) {
      // Récupération des titles Etablissement + affichage de la desc (body
      // taxo).
      var title_item = $('abbr[title]');
      if ($.fn.qtip) {
        $(title_item).qtip();
      }
    }
  };

  Drupal.behaviors.hoverListEtablissement = {
    attach: function(context) {
      // Récupération des titles Etablissement + affichage de la desc (body
      // taxo).
      $('#block-views-territoires-block-3 .views-row a').hover(
        function() {
          $(this).parent().find('.services-lies').show();
        },
        function() {
          $(this).parent().find('.services-lies').hide();
        }
      );
    }
  };

  Drupal.behaviors.formDonationListAmount = {
    attach: function(context) {
      $('div#edit-submitted-select-amount-particuliers-select div.form-type-radio').click(function() {
        $('input[name="submitted[select_amount][Entreprise][select]"]').attr('checked', false);
      });
      $('div#edit-submitted-select-amount-entreprise-select div.form-type-radio').click(function() {
        $('input[name="submitted[select_amount][Particuliers][select]"]').attr('checked', false);
      });
    }
  };

// Ajout automatique d'un paramètre lorsque le lien va s'ouvrir dans
// une lightmodal.
  Drupal.behaviors.ladaptModalFrame = {
    attach: function() {
      $("a[rel^='lightmodal']")
        .once('ladapt-lightmodal-link')
        .each(function(i, item) {
          var separator = item.href.indexOf('?') > -1 ? '&' : '?';
          item.href += separator + 'lightmodal=1';
        });
    }
  };

  Drupal.behaviors.randomBanner = {
    attach: function() {
      // var randomIndex = 1 + Math.floor(Math.random() * 4);
      // var banner = 'transparent url("' + Drupal.settings.currentTheme +
      // '/images/ban_campagne' + randomIndex + '.jpg") no-repeat scroll 0 0';
      var banner = 'transparent url("' + Drupal.settings.currentTheme + '/images/ban-header.jpg") no-repeat scroll 0 0';
      $(".l-header").css('background', banner);
      $(".l-header-wrapper").wrap('<div class="l-header-wrapper-wrapper"></div>');
    }
  };

  Drupal.behaviors.stickyHeader = {
    attach: function(context) {
      var $header = $('header#header', context),
          $body = $('body', context),
          scroll;

      $(window).scroll(function() {
          scroll = $(window).scrollTop();

          if (scroll >= 30) {
            $header.addClass('sticky');
            $body.addClass('sticky');
          } else {
            $header.removeClass('sticky');
            $body.removeClass('sticky');
          }
      });

      // Display header only when scrolling up (mobile)
      var lastScrollTop = 0;

      $(window).scroll(function(event){
        var st = $(this).scrollTop();
        if (st > lastScrollTop){
          $body.removeClass('show-header');
          $header.removeClass('show-header');
        } else {
          $body.addClass('show-header');
          $header.addClass('show-header');
        }

        lastScrollTop = st;
      });
    }
  };

  Drupal.behaviors.entrees = {
    attach: function(context) {
      var $wrapper = $('.entrees', context),
          $first = $('.entree--first', context),
          $link = $('.entrees a', context);

      $first.on('click', function(){
        $wrapper.toggleClass('open');
      });

      $link.on('focusin', function(){
        $wrapper.addClass('open');
      });

      $link.on('focusout', function(){
        $wrapper.removeClass('open');
      });
    }
  };

  Drupal.behaviors.searchPage = {
    attach: function(context) {
      var select = $('#edit-facets', context),
          $form = $('#ladapt-solr-select-facet-form', context);

      // Auto submit Par département form on select change
      $(select).on('change', function() {
          $form.submit();
      });

      // Add class active to li elements of active filters
      $('li .facetapi-active').parent('li').addClass('active');
    }
  };

  Drupal.behaviors.mobileSearchResults = {
    attach: function(context) {
      if($('div.search-results').length) {
        var windowWidth = $(window).width(),
            affinerText = Drupal.t('Affiner'),
            pageText = Drupal.t('Page'),
            filtersNumber = $('.facetapi-active', context).length,
            $div = $('.search-result', context),
            $resultsCount = $('.results-count', context),
            $sidebar = $('#sidebar-second', context),
            $affinerButton;

        // Change Affiner button text if any filter is selected
        if(filtersNumber) {
          affinerText = affinerText + ' (' + filtersNumber + ')';
        }

        // Add Affiner button to mark up
        $('#sidebar-second, .results-count', context).prepend('<a href="#" class="affiner-button">' + affinerText + '</div>');
        $affinerButton = $('body', context).find('.affiner-button');

        // Display sidebar on click on the Affiner button
        $affinerButton.on('click', function(e) {
          e.preventDefault();
          $sidebar.toggleClass('open');
        });

        // Open filter section
        $('.block-facetapi').on('click', function() {
          $(this).toggleClass('open');
        });

        // Add class open to section with active filters
        $('.block-facetapi .content .facetapi-active', context).parents('.block-facetapi').addClass('open');

        // Functions that run only if screen size is $sm-width
        $(window).on('load resize', function(){
          windowWidth = $(window).width();

          if(windowWidth <= 768) {
            // Make whole div for search result clickable
            $div.wrap(function() {
              return '<a href="' + $(this).find('a').attr('href') + '"></a>';
            });
          }
        });
      }
    }
  };

  Drupal.behaviors.messages = {
    attach: function(context) {
      var $message = $('#messages', context),
          $section = $('#messages section', context),
          fermerText = Drupal.t('Fermer'),
          $fermerButton;

      // Add fermer button to error messages
      $section.once().append('<a href="#" class="fermer-button">' + fermerText + '</a>');
      $fermerButton = $section.find('.fermer-button');

      // Hide error messages
      $fermerButton.on('click', function(e) {
          $(this).parents('#messages').addClass('slide-up');
          e.preventDefault();
      });
    }
  };

  Drupal.behaviors.formElements = {
    attach: function(context) {
      // Add button after all .form-item .description elements
      $('.form-item .description', context).each(function(){
        $(this).once().after('<a href="#" class="info-button"></a>');
        $(this).parent().find('input.form-text').addClass('have-description');
      });

      // Toggle .description on click in .info-button
      $('.info-button, .form-item .description', context).on('click', function(e) {
        $(this).parent().find('.description').toggleClass('fade-in');
        e.preventDefault();
      });

      // Display uploaded image thumbnail
      if($('span.file').length && $('span.file[type*="image"]')) {
        var fileSrc = $('span.file a', context).attr('href');

        $('span.file img', context).attr('src', fileSrc);
      }
    }
  };

  Drupal.behaviors.pageActualite = {
    attach: function(context) {
      var $groupRight = $('.group-right', context),
          $toc = $('.toc', context),
          $tocLink = $('.toc a', context),
          $tocWrapper = $('.toc-wrapper', context),
          tocWidth = $tocWrapper.width(),
          windowWidth = $(window).width(),
          stickyHeaderHeight = 76, // Header height + 30
          sommaireText = Drupal.t('Sommaire'),
          $sommaireButton,
          currentChapter;

      // Different header size for mobile
      if(windowWidth <= 768) {
        stickyHeaderHeight = 0;
      }

      // Adding sommaire button to mark up
      $toc.prepend('<li class="sommaire"><a href="#">' + sommaireText + '</a></li>');
      $sommaireButton = $toc.find('.sommaire');

      $sommaireButton.on('click', function(e) {
        $(this).parent().toggleClass('open');
        e.preventDefault();
      });

      // Scroll navigation
      var sticky = new Waypoint.Sticky({
        element: $toc,
        stuckClass: 'stick',
        offset: stickyHeaderHeight,
        handler: function(direction) {
            $toc.css('width', tocWidth);
        }
      });

      $groupRight.waypoint(function(direction) {
        if (direction == 'down') {
          $toc.css({
              position: 'absolute',
              top: $groupRight.outerHeight() - $toc.outerHeight() - stickyHeaderHeight
          });
        } else if (direction == 'up') {
            $toc.attr('style', '');
            $toc.css('width', tocWidth);
        }
      }, {
        offset: function () {
          return -$groupRight.outerHeight() + $toc.outerHeight() + stickyHeaderHeight;
        }
      });

      $('h2.chapter', context).waypoint(function(direction) {
        currentChapter = $(this.element).attr('id');

        $toc.find('a').removeClass('current');
        $toc.find('a[href="#'+ currentChapter +'"]').addClass('current');
      }, {
        offset: stickyHeaderHeight
      });

      $(window).on('resize', function(){
        windowWidth = $(window).width();
        tocWidth = $tocWrapper.width();

        $toc.css('width', tocWidth);
      });
    }
  };

  Drupal.behaviors.nodeDiaporama = {
    attach: function(context) {
      var $sliderContent = $('.group-diaporama', context),
          $sliderControls,
          $slideTitle,
          $slidePager,
          $slidePages,
          $prevButton,
          $pauseButton,
          $nextButton;

      $sliderContent.wrapAll('<div class="slider-wrapper />"');
      $slider = $sliderContent;

      $slider.after('<div class="slider-controls"><div class="slide-pager"></div></div>');
      $sliderControls = $slider.parent().find('.slider-controls');
      $slidePager = $slider.parent().find('.slide-pager');
      $slidePager.append('<ul class="slide-controls"><li class="prev"></li><li class="pause"></li><li class="next"></li></ul>');
      $prevButton = $slidePager.find('.prev');
      $pauseButton = $slidePager.find('.pause');
      $nextButton = $slidePager.find('.next');

      $sliderContent.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 4000,
        centerMode: true,
        centerPadding: '120px',
        responsive: [
          {
            breakpoint: 768,
            settings: {
              centerPadding: '0px',
            }
          }
        ]
      });


      $prevButton.on('click', function() {
        $sliderContent.slick('slickPrev');
      });

      $pauseButton.on('click', function() {
        if(!$(this).hasClass('paused')) {
          $sliderContent.slick('slickPause');
          $(this).addClass('paused');
        } else {
          $sliderContent.slick('slickPlay');
          $(this).removeClass('paused');
        }
      });

      $nextButton.on('click', function() {
        $sliderContent.slick('slickNext');
      });
    }
  };

  Drupal.behaviors.homepageSlider = {
    attach: function(context) {
      var $sliderContent = $('.mea-slider-home > .content', context),
          $slider = $('.mea-slider-home'),
          $sliderControls,
          $slideTitle,
          $slidePager,
          $slidePages,
          $prevButton,
          $pauseButton,
          $nextButton;

      $slider.append('<div class="slider-controls"><div class="slide-title"></div><div class="slide-pager"></div></div>');
      $sliderControls = $slider.find('.slider-controls');
      $slideTitle = $slider.find('.slide-title');
      $slidePager = $slider.find('.slide-pager');

      $slidePager.append('<div class="slide-pages"><span class="current-page">1</span>/<span class="total-pages"></span></div><ul class="slide-controls"><li class="prev"></li><li class="pause"></li><li class="next"></li></ul>');
      $slidePages = $slidePager.find('.slide-pages');
      $prevButton = $slidePager.find('.prev');
      $pauseButton = $slidePager.find('.pause');
      $nextButton = $slidePager.find('.next');

      $sliderContent.on('init', function(event, slick){
        $slideTitle.html($('.slick-current').find('h2').html());
        $slidePages.find('.total-pages').html(slick.slideCount);
      });

      $sliderContent.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 4000,
      });

      $sliderContent.on('beforeChange', function(event, slick, currentSlide){
        $slideTitle.removeClass('slide-in');
        $slideTitle.addClass('fade-out');
      });

      $sliderContent.on('afterChange', function(event, slick, currentSlide){
        $slideTitle.removeClass('fade-out');
        $slideTitle.addClass('slide-in');
        $slideTitle.html($('.slick-current').find('h2').html());
        $slidePages.find('.current-page').html(parseInt(currentSlide) + 1);
      });

      $prevButton.on('click', function() {
        $sliderContent.slick('slickPrev');
      });

      $pauseButton.on('click', function() {
        if(!$(this).hasClass('paused')) {
          $sliderContent.slick('slickPause');
          $(this).addClass('paused');
        } else {
          $sliderContent.slick('slickPlay');
          $(this).removeClass('paused');
        }
      });

      $nextButton.on('click', function() {
        $sliderContent.slick('slickNext');
      });
    }
  };


  Drupal.behaviors.actualitesSlider = {
    attach: function(context) {
      var $sliderContent = $('#block-views-territoires-block .view-content', context);

    $sliderContent.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        infinite: false,
        dots: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              dots: true,
            }
          }
        ]
      });
    }
  };

  Drupal.behaviors.videos = {
    attach: function(context) {
      var $video = $('.type-video iframe', context),
          videoWidth,
          videoHeight,
          newWidth,
          newheight;

      function videoResize() {
        $video.each(function() {
          videoWidth = $(this).attr('width');
          videoHeight = $(this).attr('height');
          ratio = videoWidth/videoHeight;

          newWidth = $(this).width();
          newHeight = newWidth/ratio;

          $(this).css('height', newHeight + 'px');
        });
      }

      videoResize();

      $(window).on('resize', function(){
        videoResize();
      });
    }
  };

  Drupal.behaviors.ladaptReadmore = {
    attach: function(context, settings) {
      $('.ladapt-read-more', context).each(function() {
        var me = $(this),
          maxItems = me.data('maxItems'),
          rel = me.attr('rel');
        me.parent().find(rel + ' > *').slice(maxItems).hide();
        me.on('click', function() {
          me.parent().find(rel + ' > *').show({
            duration: 300,
            complete: function() {me.hide();}
          });
        });
      });
    }
  };

  Drupal.behaviors.RogerVoice = {
    attach: function(context, settings) {
      var rogeraccess = $('.rogervoice', context);
      rogeraccess.on('click', function(e) {
        e.preventDefault();
        Rogeraccess('open');
      });
    }
  };

  Drupal.behaviors.ModalContact = {
    attach: function (context, settings) {
      var modal = $('.modal', context);
      var button = $('.contact-button', context);
      var closebutton = modal.find('.close');

      button.on('click', function() {
        modal.css('display', 'block');
      });

      closebutton.on('click', function() {
        modal.css('display', 'none');
      });

      $(document).on('keydown', function (e) {
        // escape button.
        if (e.keyCode === 27) {
          modal.css('display', 'none');
        }
      });
    }
  };

  Drupal.behaviors.newsletter = {
    attach: function (context, settings) {
      const $form = $('.block-simplenews form.unlogged-subscribe > div');
      if ($form.length > 0) {
        const $submitButton = $('input[type="submit"]', $form);
        const $captcha = $('.form-wrapper.captcha', $form);

        $submitButton.hide();
        $captcha.hide();

        $form.append($('<input class="button" type="button" value="' + $submitButton.attr('value') + '" />'));

        const $button = $('.button', $form)
        const showCaptcha = function() {
          $button.hide();
          $submitButton.show();
          $captcha.show();
          $captcha.toggleClass('captcha--visible')
        };

        $('.button', $form).once().on('click', function(){
          showCaptcha();
        });
        $('input[name="mail"]', $form).once().on('keypress', function(e){
          var keycode = (e.keyCode ? e.keyCode : e.which);
          if (keycode == '13') {
            showCaptcha();
            return false;
          }
        });
      }
    }
  };

})(jQuery);
