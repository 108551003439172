(function ($, window) {

  'use strict';

  Drupal.behaviors.ladaptNavigation = {
    attach: function (context, settings) {
      $(".hamburger .hlabel", context).on('click', function () {
        if($('body', context).is('.recherche-opened')) {
          $('body', context).removeClass('recherche-opened');
          $('.dialog', context).dialog('close');
        }

        $('.hamburger', context).toggleClass('open');
        $('header', context).toggleClass('open');
        $('#menu-principal', context).toggleClass('element-invisible');
        $('#lightbox2-overlay', context).fadeToggle();
        $('html', context).toggleClass('no-scroll');
      });

      $('#lightbox2-overlay').on('click', function() {
        if($('.hamburger', context).hasClass('open')) {
          $('.hamburger', context).toggleClass('open');
          $('header', context).toggleClass('open');
          $('#menu-principal', context).toggleClass('element-invisible');
          $('html', context).toggleClass('no-scroll');
        }
      });

      /*$(document).on('touchmove', 'html.no-scroll', context, function(e){
	       e.preventDefault();
      }, false);*/
    }
  };

  $(window).on('hashchange', function() {
    if (location.hash.slice(1) === 'navigation') {
      $('#menu-principal', context).removeClass('element-invisible');
    }
  });

})(jQuery, window);
