(function ($) {

  'use strict';

  Drupal.behaviors.ladaptActionsSlider = {
    attach: function () {
      $('.view-display-id-block_1 .view-content').nanoScroller({
        contentClass: 'item-list'
      });

      $('.view-display-id-block_1 .view-content').append('<div class="gradient"></div>');

      if (jQuery.fn.flexslider) {
        $('.flexslider2').flexslider({
          animation: "slide",
          direction: "vertical",
          slideshow: false,
          directionNav: false,
          manualControls: ".control-nav li",
          multipleKeyboard: true,
        });
      }
    }
  };

  Drupal.behaviors.ladaptActusSlider = {
    attach: function () {
      // Equal height on actus
      var items = $('div.carousel-actus li figure');
      var maxHeight = 0;
      $(items).each(function () {
        var height = $(this).height() + 20;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });
      $(items).height(maxHeight);

      if (jQuery.fn.flexslider) {
        $("div.carousel-actus")
          .once('actus-slider')
          .flexslider({animation: "slide", itemWidth: 380});

        window.setTimeout(function () {
          $('ol.flex-control-nav').attr('role', 'presentation');
        }, 1000);
      }
    }
  };

})(jQuery);
